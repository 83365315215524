import React from "react";

const Book = () => {
  return (
    <section className="relative py-12 bg-gray-900 sm:py-16 lg:py-20">
      <div className="absolute inset-0">
        <img
          className="object-cover w-full h-full"
          src="images/it2.png"
          alt=""
        />
      </div>

      <div className="relative px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="flex justify-end max-w-5xl mx-auto">
          <div className="overflow-hidden bg-white md:w-1/2">
            <div className="p-8 sm:py-10 sm:px-12">
              <p className="mt-4 text-2xl font-bold text-gray-900 sm:text-3xl">
                <a href="#" title="" className="">
                  YAF International:
                </a>
              </p>
              <p className="mt-6 text-base font-normal leading-7 text-gray-900">
                Innovating IT, Garments, and Restaurant Solutions.Empowering
                Your Business with Tailored Tech Excellence.
              </p>
              <div className="mt-16">
                <a
                  href="/contact"
                  title=""
                  className="inline-flex items-center text-xs font-bold tracking-widest text-gray-900 uppercase group"
                >
                  Contact Us
                  <svg
                    className="w-4 h-4 ml-2 transition-all duration-200 transform group-hover:translate-x-1"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M13 7l5 5m0 0l-5 5m5-5H6"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Book;
