import React from "react";

const Hero = () => {
  return (
    <div className="">
      <section className="relative pt-12 overflow-hidden bg-[#F3FBF9] sm:pt-16">
        <div className="relative px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="max-w-4xl mx-auto text-center">
            <p className="text-sm font-normal tracking-widest uppercase"></p>
            <h1 className="mt-8 text-4xl font-normal text-black sm:text-5xl lg:text-6xl xl:text-7xl">
              Streamline with our expert BPO services
            </h1>

            <div className="flex flex-col items-center justify-center px-8 mt-12 space-y-5 sm:space-y-0 sm:px-0 sm:space-x-5 sm:flex-row">
              <div className="relative inline-flex items-center justify-center w-full sm:w-auto group">
                {/* <div className="absolute transition-all duration-200 rounded-full -inset-px bg-gradient-to-r from-cyan-500 to-purple-500 group-hover:shadow-lg group-hover:shadow-cyan-500/50"></div> */}
                <a
                  href="#"
                  title=""
                  className="relative inline-flex items-center justify-center w-full px-8 py-3 text-base font-normal text-white bg-black border border-transparent rounded-full sm:w-auto"
                  role="button"
                >
                  {" "}
                  Contact Us
                </a>
              </div>
            </div>
          </div>

          <div className="relative mt-12 -mb-4 sm:-mb-10 lg:-mb-12 sm:mt-16 lg:mt-24">
            {/* <div className="absolute top-0 transform -translate-x-1/2 left-1/2">
          <svg
            className="blur-3xl filter"
            // style="filter: blur(64px)"
            width="645"
            height="413"
            viewBox="0 0 645 413"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M181.316 218.778C86.2529 123.715 -63.7045 134.94 31.3589 39.8762C126.422 -55.1873 528.427 41.1918 623.49 136.255C718.554 231.319 470.678 289.068 375.614 384.131C280.551 479.195 276.38 313.842 181.316 218.778Z"
              fill="url(#d)"
            />
            <defs>
              <linearGradient
                id="d"
                x1="665.741"
                y1="178.506"
                x2="296.286"
                y2="474.62"
                gradientUnits="userSpaceOnUse"
              >
                <stop
                  offset="0%"
                  // style="stop-color: var(--color-cyan-500)"
                />
                <stop
                  offset="100%"
                  // style="stop-color: var(--color-purple-500)"
                />
              </linearGradient>
            </defs>
          </svg>
        </div> */}

            {/* <div className="absolute inset-0">
              <img
                className="object-cover w-full h-full opacity-50"
                src="https://landingfoliocom.imgix.net/store/collection/dusk/images/noise.png"
                alt=""
              />
            </div> */}

            <img
              className="relative w-full max-w-5xl mx-auto"
              src="images/bpo1.png"
              alt=""
            />
          </div>
        </div>
      </section>
      <section className="py-12 bg-white sm:py-16 lg:py-20 xl:py-24">
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-12">
            <div className="flex flex-col justify-between lg:order-2">
              <div className="flex-1">
                <span className="inline-flex items-center px-3 py-2 text-sm font-medium text-blue-600 rounded-xl bg-blue-50">
                  About Our BPO YAF BPO
                </span>

                <h1 className="mt-6 text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl xl:text-7xl sm:tracking-tight">
                  YAF BPO: Your Partner in Business Growth
                </h1>
              </div>

              <div className="mt-6 lg:mt-auto">
                <p className="text-lg leading-7 text-gray-700 lg:leading-8 lg:text-xl">
                  At YAF BPO, we set ourselves apart from the competition by
                  prioritizing the seamless integration of talent into our
                  clients' operations. Our approach revolves around close
                  collaboration, where we place your culture and brand identity
                  front and center in our staffing solutions. We're dedicated to
                  assembling high-performing teams that mirror your vision,
                  mission, and organizational culture. Your dedicated YAF BPO
                  offices are tailored to your specifications, mirroring your
                  workspace and equipped with cutting-edge technology. Our
                  success lies in providing silent support, allowing your team
                  and business to flourish. We further amplify your efforts
                  through continuous staff development and engagement
                  initiatives throughout their careers.
                </p>
                <div className="mt-10"></div>
              </div>
            </div>

            <div className="lg:order-1">
              <div className="relative w-full max-w-sm mx-auto">
                <img
                  className="relative w-full max-w-xs mx-auto sm:max-w-sm rounded-2xl"
                  src="images/bpo2.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* feature  */}
      <section className="py-12 bg-white sm:py-16 lg:py-20">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold leading-tight text-gray-900 sm:text-4xl xl:text-5xl font-pj">
              Our BPO Services
            </h2>
            {/* <p className="mt-4 text-base leading-7 text-gray-600 sm:mt-8 font-pj">
              Lorem ipsum dolor sit amet, consectetur adipis elit
            </p> */}
          </div>

          <div className="grid grid-cols-1 mt-10 text-center sm:mt-16 sm:grid-cols-2 sm:gap-x-12 gap-y-12 md:grid-cols-3 md:gap-0 xl:mt-24">
            <div className="md:p-8 lg:p-14">
              <img className="mx-auto" src="images/icon1.png" alt="" />
              <h3 className="mt-12 text-xl font-bold text-gray-900 font-pj">
                Accounting
              </h3>
              <p className="mt-5 text-base text-gray-600 font-pj">
                Our proficient team of finance experts delivers high-value
                professional services, adeptly handling diverse financial tasks
                to drive your organization's growth and success.
              </p>
            </div>

            <div className="md:p-8 lg:p-14 md:border-l md:border-gray-200">
              <img className="mx-auto" src="images/icon2.png" alt="" />

              <h3 className="mt-12 text-xl font-bold text-gray-900 font-pj">
                Back Office
              </h3>
              <p className="mt-5 text-base text-gray-600 font-pj">
                Our extensive talent pool comprises skilled professionals with
                expertise in various industries outsourcing services,
                encompassing property management, marketing, sales, research,
                and administrative tasks.
              </p>
            </div>

            <div className="md:p-8 lg:p-14 md:border-l md:border-gray-200">
              <img className="mx-auto" src="images/icon3.png" alt="" />

              <h3 className="mt-12 text-xl font-bold text-gray-900 font-pj">
                Customer Service
              </h3>
              <p className="mt-5 text-base text-gray-600 font-pj">
                Our BPO offers 24/7 outsourced customer service to enhance
                customer satisfaction, loyalty, retention, and sales. This
                cost-effective solution promotes business growth by delivering
                prompt, knowledgeable, and friendly support to satisfy
                customers.
              </p>
            </div>

            <div className="md:p-8 lg:p-14 md:border-t md:border-gray-200">
              <img className="mx-auto" src="images/icon4.png" alt="" />

              <h3 className="mt-12 text-xl font-bold text-gray-900 font-pj">
                Health Care
              </h3>
              <p className="mt-5 text-base text-gray-600 font-pj">
                We stay current with healthcare regulations, ensuring compliance
                and delivering adaptable, high-quality services to meet evolving
                challenges.
              </p>
            </div>

            <div className="md:p-8 lg:p-14 md:border-l md:border-gray-200 md:border-t">
              <img className="mx-auto" src="images/icon5.png" alt="" />

              <h3 className="mt-12 text-xl font-bold text-gray-900 font-pj">
                IT & Development
              </h3>
              <p className="mt-5 text-base text-gray-600 font-pj">
                At YAF BPO, we craft specialized IT teams for technical support,
                ensuring timely assistance for software concerns. Our offshore
                experts stay updated on software functionalities to provide
                seamless solutions.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="relative py-12 bg-gray-900 sm:py-16 lg:py-20">
        <div className="absolute inset-0">
          <img
            className="object-cover w-full h-full"
            src="images/it2.png"
            alt=""
          />
        </div>

        <div className="relative px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="flex justify-end max-w-5xl mx-auto">
            <div className="overflow-hidden bg-white md:w-1/2">
              <div className="p-8 sm:py-10 sm:px-12">
                <p className="mt-4 text-2xl font-bold text-gray-900 sm:text-3xl">
                  <a href="#" title="" className="">
                    YAF International:
                  </a>
                </p>
                <p className="mt-6 text-base font-normal leading-7 text-gray-900">
                  Innovating IT, Garments, and Restaurant Solutions.Empowering
                  Your Business with Tailored Tech Excellence.
                </p>
                <div className="mt-16">
                  <a
                    href="/contact"
                    title=""
                    className="inline-flex items-center text-xs font-bold tracking-widest text-gray-900 uppercase group"
                  >
                    Contact Us
                    <svg
                      className="w-4 h-4 ml-2 transition-all duration-200 transform group-hover:translate-x-1"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M13 7l5 5m0 0l-5 5m5-5H6"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Hero;
