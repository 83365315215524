import "./App.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Home from "./component/home/Home";
import CFC from "./component/service/cfc/CFC";
import It from "./component/service/it/It";
import Garments from "./component/service/garments/Garments";
import Contact from "./component/contact/Contact";
import Bpo from "./component/service/bpo/Bpo";
import AboutUs from "./component/about-us/AboutUs";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/cfc" element={<CFC />} />
        <Route path="/it" element={<It />} />
        <Route path="/garments" element={<Garments />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/bpo" element={<Bpo />} />
        <Route path="/about-us" element={<AboutUs />} />
      </Routes>
    </Router>
  );
}

export default App;
