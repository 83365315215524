import React from "react";

const Feature = () => {
  return (
    <section className="py-10 bg-gray-50 sm:py-16 lg:py-24">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="grid items-stretch gap-y-10 md:grid-cols-2 md:gap-x-20">
          <div className="relative grid grid-cols-2 gap-6 mt-10 md:mt-0">
            <div className="overflow-hidden aspect-w-3 aspect-h-4">
              <img
                className="object-cover object-top origin-top scale-150"
                src="images/home2.png"
                alt=""
              />
            </div>

            <div className="relative">
              <div className="h-full overflow-hidden aspect-w-3 aspect-h-4">
                <img
                  className="object-cover scale-150 lg:origin-bottom-right"
                  src="images/home3.png"
                  alt=""
                />
              </div>

              <div className="absolute inset-0 grid w-full h-full place-items-center"></div>
            </div>

            <div className="absolute -translate-x-1/2 left-1/2 -top-16">
              <img
                className="w-32 h-32"
                src="https://cdn.rareblocks.xyz/collection/celebration/images/features/2/round-text.png"
                alt=""
              />
            </div>
          </div>

          <div className="flex flex-col items-start xl:px-16">
            <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">
              About Us
            </h2>
            <p className="mt-4 text-base leading-relaxed text-gray-600">
              At Yaf Trading, we are embarking on a journey to explore new
              horizons in various sectors, including retail and the IT industry.
              With a vision for innovation and growth, we aim to leverage our
              expertise and resources to create lasting value for our customers
              and stakeholders.
            </p>

            <a
              href="#"
              title=""
              className="inline-flex items-center justify-center px-5 py-4 mt-8 text-base font-semibold text-white transition-all duration-200 rounded-md hover:opacity-90 focus:opacity-90 lg:mt-auto bg-gradient-to-r from-fuchsia-600 to-blue-600"
              role="button"
            >
              Read More
              <svg
                className="w-5 h-5 ml-8 -mr-1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feature;
