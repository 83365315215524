import React from "react";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import Hero from "./Hero";

const Garments = () => {
  return (
    <div>
      <Header />
      <Hero />
      <Footer />
    </div>
  );
};

export default Garments;
