import React, { useState } from "react";

const Faq = () => {
  const [faq, setFaq] = useState([
    {
      question:
        "What sets Yaf Trading Co. Ltd apart from other trading enterprises?",
      answer:
        "Yaf Trading Co. Ltd distinguishes itself through its dynamic approach to business, backed by the extensive experience and commitment to excellence of the Impressive Group. We strive to explore new horizons, uphold a legacy of excellence in garment manufacturing, and prioritize safety and worker welfare.",
      open: false,
    },
    {
      question: "What sectors does Yaf Trading Co. Ltd operate in?",
      answer:
        "Yaf Trading Co. Ltd is involved in various sectors, including retail and the IT industry. Our vision for innovation and growth propels us to explore opportunities across diverse domains, creating lasting value for our customers and stakeholders",
      open: false,
    },
    {
      question:
        "What is the legacy of garment manufacturing associated with Yaf Trading Co. Ltd?",
      answer:
        "Yaf Trading Co. Ltd boasts a legacy of excellence in garment manufacturing, supported by three renowned garment factories specializing in woven garments for top and bottom wear. Our dedication to quality and timely delivery ensures that every product meets the highest standards of craftsmanship.",
      open: false,
    },
    {
      question:
        "How does Yaf Trading Co. Ltd prioritize safety and worker welfare?",
      answer:
        "At Yaf Trading Co. Ltd, the safety and well-being of our workers are central to our ethos. We provide a healthy and nurturing work environment, adhering to rigorous safety protocols across all our factories. Our commitment to European and USA buyer standards underscores our dedication to worker welfare.",
      open: false,
    },
    {
      question:
        "What culinary offerings does California Fried Chicken & Pastry Shop provide?",
      answer:
        "California Fried Chicken & Pastry Shop, a venture of Yaf Trading Co. Ltd, offers international-standard fast food and pastry options. With experienced chefs and staff trained in renowned establishments, we guarantee a culinary experience that prioritizes quality and customer satisfaction.",
      open: false,
    },
    {
      question: "Is Yaf Trading Co. Ltd open to investment opportunities?",
      answer:
        "Yes, Yaf Trading Co. Ltd actively seeks investment opportunities to diversify its portfolio and expand its footprint in Thailand and beyond. With a proven track record of success and a passion for growth, we welcome partnerships that align with our vision of innovation and excellence.",
      open: false,
    },
    {
      question:
        "How can businesses and investors collaborate with Yaf Trading Co. Ltd?",
      answer:
        "Businesses and investors who share our vision for shaping the future of commerce and industry are invited to join us on our journey. Together, we can explore new horizons, unlock untapped potential, and build a brighter tomorrow. Reach out to us to explore collaboration opportunities and become part of our dynamic ecosystem.",
      open: false,
    },
    {
      question: "What services does YAF Trading BPO offer?",
      answer:
        "YAF Trading BPO offers a range of outsourcing services tailored to meet various business needs. These may include customer support, data entry, virtual assistant services, content moderation, and more.",
      open: false,
    },
    {
      question:
        "How can outsourcing with YAF Trading BPO benefit my business?        ",
      answer:
        "Outsourcing with YAF Trading BPO can provide numerous benefits, such as cost savings, access to specialized expertise, scalability, improved efficiency, and enhanced focus on core business activities.",
      open: false,
    },
    {
      question: "What industries does YAF Trading BPO cater to?        ",
      answer:
        "YAF Trading BPO serves clients across diverse industries, including but not limited to e-commerce, healthcare, technology, finance, and telecommunications.        ",
      open: false,
    },
    {
      question:
        "How are the BPO professionals selected and trained by YAF Trading BPO?        ",
      answer:
        "YAF Trading BPO follows a rigorous selection process to onboard talented professionals who meet specific operational standards and possess the necessary skills. Our professionals undergo comprehensive training to ensure they are equipped to deliver exceptional service.",
      open: false,
    },
    {
      question:
        "How does YAF Trading BPO ensure quality assurance in its outsourcing services?  ",
      answer:
        "YAF Trading BPO maintains a robust quality assurance process to uphold service excellence. This includes regular performance monitoring, feedback mechanisms, continuous training programs, and adherence to predefined service level agreements (SLAs).",
      open: false,
    },
  ]);

  const toggleFaq = (index) => {
    setFaq(
      faq.map((item, i) => {
        if (i === index) {
          item.open = !item.open;
        } else {
          item.open = false;
        }

        return item;
      })
    );
  };

  return (
    <section className="py-10 bg-gray-50 sm:py-16 lg:py-24">
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="max-w-2xl mx-auto text-center">
          <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">
            Frequently Asked Questions
          </h2>
          <p className="max-w-xl mx-auto mt-4 text-base leading-relaxed text-gray-600">
            Frequently asked questions by ours customers.
          </p>
        </div>

        <div className="max-w-3xl mx-auto mt-8 space-y-4 md:mt-16">
          {faq.map((item, index) => (
            <div
              key={index}
              className="transition-all duration-200 bg-white border border-gray-200 cursor-pointer hover:bg-gray-50"
            >
              <button
                type="button"
                className="flex items-center justify-between w-full px-4 py-5 sm:p-6"
                onClick={() => toggleFaq(index)}
              >
                <span className="flex text-lg font-semibold text-black">
                  {" "}
                  {item.question}{" "}
                </span>

                <svg
                  className={`w-6 h-6 text-gray-400 ${
                    item.open ? "rotate-180" : ""
                  }`}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>

              <div
                className={`${
                  item.open ? "block" : "hidden"
                } px-4 pb-5 sm:px-6 sm:pb-6`}
              >
                <p dangerouslySetInnerHTML={{ __html: item.answer }}></p>
              </div>
            </div>
          ))}
        </div>

        <p className="text-center text-gray-600 textbase mt-9">
          Didn’t find the answer you are looking for?{" "}
          <a
            href="#"
            title=""
            className="font-medium text-blue-600 transition-all duration-200 hover:text-blue-700 focus:text-blue-700 hover:underline"
          >
            Contact our support
          </a>
        </p>
      </div>
    </section>
  );
};

export default Faq;
