import React, { useState } from "react";

const Header = () => {
  const [expanded, setExpanded] = useState(false);

  const toggleMenu = () => {
    setExpanded(!expanded);
  };

  return (
    <header className="py-4 bg-white">
      <div className="container px-4 mx-auto sm:px-6 lg:px-8">
        <div className={"flex items-center justify-between"}>
          <div className="flex-shrink-0">
            <a href="/" title="" className="flex">
              <img
                className=""
                width={"70"}
                height={"50%"}
                src="images/yaf.png"
                alt=""
              />
            </a>
          </div>

          <div className="flex lg:hidden">
            <button
              type="button"
              className="text-gray-900  "
              onClick={toggleMenu}
              aria-expanded={expanded}
            >
              <span aria-hidden="true">
                <svg
                  className="w-7 h-7"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  {expanded ? (
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  ) : (
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  )}
                </svg>
              </span>
            </button>
          </div>

          {/* <nav className={`lg:hidden ${expanded ? "" : "hidden"}`}>
            <div className="px-1 py-8">
              <div className="grid gap-y-7">
                <a
                  href="#"
                  title=""
                  className="flex items-center p-3 -m-3 text-base font-medium text-gray-900 transition-all duration-200 rounded-xl hover:bg-gray-50 focus:outline-none font-pj focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"
                >
                  {" "}
                  Features{" "}
                </a>

                <a
                  title=""
                  className="flex items-center p-3 -m-3 text-base font-medium text-gray-900 transition-all duration-200 rounded-xl hover:bg-gray-50 focus:outline-none font-pj focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"
                >
                  {" "}
                  Our Services
                </a>

                <a
                  href="#"
                  title=""
                  className="flex items-center p-3 -m-3 text-base font-medium text-gray-900 transition-all duration-200 rounded-xl hover:bg-gray-50 focus:outline-none font-pj focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"
                >
                  {" "}
                  About Us{" "}
                </a>

                <a
                  href="#"
                  title=""
                  className="flex items-center p-3 -m-3 text-base font-medium text-gray-900 transition-all duration-200 rounded-xl hover:bg-gray-50 focus:outline-none font-pj focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"
                >
                  {" "}
                  Faq
                </a>

                <a
                  href="#"
                  title=""
                  className="inline-flex items-center justify-center px-6 py-2 text-base font-bold leading-7 text-white transition-all duration-200 bg-gray-900 border border-transparent rounded-xl hover:bg-gray-600 font-pj focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                  role="button"
                >
                  Contact Us
                </a>
              </div>
            </div>
          </nav> */}

          <nav class="hidden lg:items-center lg:ml-16 lg:mr-auto lg:space-x-18 lg:flex">
            <a
              href="/about-us"
              title=""
              class="text-base font-medium w-[110px]  text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"
            >
              About us
            </a>
            {/* <a
              href="#"
              title=""
              class="text-base font-medium text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"
            >
              About us
            </a> */}

            <div class="mx-auto flex  w-full items-center justify-center">
              <div class="group relative cursor-pointer">
                <div class="flex items-center justify-between space-x-6 bg-white px-0">
                  <p class="menu-hover  text-base font-medium text-black lg:mx-4">
                    Our Services
                  </p>
                </div>

                <div class="invisible absolute z-50 flex w-full flex-col bg-white  px-4 text-gray-800 shadow-xl group-hover:visible">
                  <a
                    href="/it"
                    class="my-2 block border-b border-gray-100 py-1 font-semibold text-gray-500 hover:text-black md:mx-2"
                  >
                    IT
                  </a>

                  <a
                    href="garments"
                    class="my-2 block border-b border-gray-100 py-1 font-semibold text-gray-500 hover:text-black md:mx-2"
                  >
                    GARMENTS
                  </a>

                  <a
                    href="/cfc"
                    class="my-2 block border-b border-gray-100 py-1 font-semibold text-gray-500 hover:text-black md:mx-2"
                  >
                    CFC
                  </a>
                  <a
                    href="/bpo"
                    class="my-2 block border-b border-gray-100 py-1 font-semibold text-gray-500 hover:text-black md:mx-2"
                  >
                    BPO
                  </a>
                </div>
              </div>
            </div>

            <a
              href="#"
              title=""
              class="text-base font-medium text-gray-900 transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"
            >
              Faq
            </a>
          </nav>

          <nav className="hidden lg:flex lg:items-center lg:justify-end lg:space-x-10">
            <a
              href="/contact"
              title=""
              className="inline-flex items-center justify-center px-6 py-2 text-base font-bold leading-7 text-white transition-all duration-200 bg-gray-900 border border-transparent rounded-xl hover:bg-gray-600 font-pj focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
              role="button"
            >
              Contact Us
            </a>
          </nav>
        </div>
        <nav className={`lg:hidden ${expanded ? "" : "hidden"}`}>
          <div className="px-1 py-8">
            <div className="grid gap-y-7">
              <div class=" flex  w-full ">
                <div class="group relative cursor-pointer">
                  <div class="flex  justify-between space-x-6 bg-white px-0">
                    <p class="menu-hover  text-base font-medium text-black lg:mx-4">
                      Our Services
                    </p>
                  </div>

                  <div class="invisible absolute z-50 flex w-[150px] flex-col bg-white  px-4 text-gray-800 shadow-xl group-hover:visible">
                    <a
                      href="/it"
                      class="my-2 block border-b border-gray-100 py-1 font-semibold text-gray-500 hover:text-black md:mx-2"
                    >
                      IT
                    </a>

                    <a
                      href="garments"
                      class="my-2 block border-b border-gray-100 py-1 font-semibold text-gray-500 hover:text-black md:mx-2"
                    >
                      GARMENTS
                    </a>

                    <a
                      href="/cfc"
                      class="my-2 block border-b border-gray-100 py-1 font-semibold text-gray-500 hover:text-black md:mx-2"
                    >
                      CFC
                    </a>
                    <a
                      href="/bpo"
                      class="my-2 block border-b border-gray-100 py-1 font-semibold text-gray-500 hover:text-black md:mx-2"
                    >
                      BPO
                    </a>
                  </div>
                </div>
              </div>

              <a
                href="/about-us"
                title=""
                className="flex items-center p-3 -m-3 text-base font-medium text-gray-900 transition-all duration-200 rounded-xl hover:bg-gray-50 focus:outline-none font-pj focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"
              >
                {" "}
                About Us{" "}
              </a>

              <a
                title=""
                className="flex items-center p-3 -m-3 text-base font-medium text-gray-900 transition-all duration-200 rounded-xl hover:bg-gray-50 focus:outline-none font-pj focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"
              >
                {" "}
                Faq
              </a>

              <a
                href="#"
                title=""
                className="inline-flex items-center justify-center px-6 py-2 text-base font-bold leading-7 text-white transition-all duration-200 bg-gray-900 border border-transparent rounded-xl hover:bg-gray-600 font-pj focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                role="button"
              >
                Contact Us
              </a>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
