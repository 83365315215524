import React from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";

const AboutUs = () => {
  return (
    <div>
      <Header />
      <section className="py-12 bg-white sm:py-16 lg:py-4">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto xl:max-w-4xl">
            <h1 className="text-3xl font-bold text-gray-900 mt-7 sm:text-4xl xl:text-5xl font-pj">
              About Us
            </h1>

            <div className="mt-10">
              <p className="text-base font-normal leading-7 text-gray-700 font-pj">
                Yaf Trading Co. Ltd, a dynamic trading enterprise poised to make
                its mark in Thailand's diverse business landscape. As a proud
                member of the esteemed Impressive Group, we bring a wealth of
                experience and a commitment to excellence to every endeavor we
                undertake.
              </p>

              <h2 className="mt-12 text-3xl font-bold text-gray-900 font-pj">
                Our Vision: Exploring New Horizons
              </h2>
              <p className="mt-6 text-base font-normal leading-7 text-gray-700 font-pj">
                At Yaf Trading, we are embarking on a journey to explore new
                horizons in various sectors, including retail and the IT
                industry. With a vision for innovation and growth, we aim to
                leverage our expertise and resources to create lasting value for
                our customers and stakeholders.
              </p>
              {/* <ul className="pl-5 mt-6 space-y-1 text-base font-normal leading-7 text-gray-700 list-disc list-outside font-pj">
                <li>
                  Id elementum velit posuere vestibulum lectus id eu
                  sollicitudin.
                </li>
                <li>Tellus amet bibendum sit mus phasellus quis duis lacus.</li>
                <li>Enim eget sagittis, auctor iaculis nulla semper.</li>
              </ul> */}

              <h2 className="mt-12 text-3xl font-bold text-gray-900 font-pj">
                Legacy of Excellence in Garment Manufacturing
              </h2>
              <p className="mt-6 text-base font-normal leading-7 text-gray-700 font-pj">
                At the heart of our operations lies a legacy of excellence in
                garment manufacturing. As part of the Impressive Group,
                Bangladesh, we are backed by three renowned garment factories
                specializing in woven garments for top and bottom wear. Our
                unwavering dedication to quality and timely delivery sets us
                apart in the industry, ensuring that every product bearing our
                name meets the highest standards of craftsmanship.
              </p>

              <h2 className="mt-12 text-3xl font-bold text-gray-900 font-pj">
                Commitment to Safety and Worker Welfare
              </h2>
              <p className="mt-6 text-base font-normal leading-7 text-gray-700 font-pj">
                Central to our ethos is a steadfast commitment to the safety and
                well-being of our workers. We prioritize providing a healthy and
                nurturing work environment, ensuring that our employees feel
                valued and supported. With compliance to European and USA buyer
                standards, we uphold rigorous safety protocols across all our
                factories, safeguarding the welfare of our workforce.
              </p>

              <h2 className="mt-12 text-3xl font-bold text-gray-900 font-pj">
                Introducing California Fried Chicken & Pastry Shop
              </h2>
              <p className="mt-6 text-base font-normal leading-7 text-gray-700 font-pj">
                In addition to our garment manufacturing operations, we are
                proud to introduce California Fried Chicken & Pastry shop,
                setting the bar for international-standard fast food and pastry
                offerings. With a team of experienced chefs and staff who have
                honed their skills in renowned international hotels and
                fast-food establishments, we guarantee a culinary experience
                that is second to none. Our commitment to quality extends to
                every aspect of our operations, from the ingredients we use to
                the machinery in our factories and outlets. Our goal is to serve
                delicious and healthy offerings at a reasonable price,
                prioritizing customer satisfaction above all else.
              </p>

              <h2 className="mt-12 text-3xl font-bold text-gray-900 font-pj">
                BPO Services: Your Partner in Outsourcing Excellence
              </h2>
              <p className="mt-6 text-base font-normal leading-7 text-gray-700 font-pj">
                Alongside our diverse business ventures, Yaf Trading Co. Ltd
                offers comprehensive Business Process Outsourcing (BPO)
                services. We specialize in providing offshore staffing solutions
                tailored to meet your specific operational needs. From customer
                support and data entry to virtual assistant services, our team
                of skilled professionals ensures seamless integration with your
                brand identity and organizational culture. With a commitment to
                excellence and a track record of success, we empower businesses
                to streamline operations, reduce costs, and achieve greater
                efficiency.
              </p>
              <h2 className="mt-12 text-3xl font-bold text-gray-900 font-pj">
                Exploring Investment Opportunities
              </h2>
              <p className="mt-6 text-base font-normal leading-7 text-gray-700 font-pj">
                In our pursuit of excellence and innovation, Yaf Trading Co. Ltd
                is actively seeking opportunities to invest in the right
                sectors, further diversifying our portfolio and expanding our
                footprint in Thailand and beyond. With a proven track record of
                success, a commitment to quality, and a passion for growth, we
                are poised to seize new opportunities and create value for our
                partners, investors, and the communities we serve.
              </p>

              <div className="relative mt-16">
                <div className="absolute -inset-2">
                  <div
                    className="w-full h-full mx-auto opacity-30 blur-lg filter"
                    // style="background: linear-gradient(90deg, #44ff9a -0.55%, #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%)"
                  ></div>
                </div>

                <img
                  className="relative rounded-xl"
                  src="images/about-us.png"
                  alt=""
                />
              </div>

              <h2 className="mt-16 text-3xl font-bold text-gray-900 font-pj">
                Join Us in Shaping the Future
              </h2>
              <p className="mt-6 text-base font-normal leading-7 text-gray-700 font-pj">
                As we embark on this exciting journey, we invite like-minded
                businesses and investors to join us in shaping the future of
                commerce and industry. Together, let us explore new horizons,
                unlock untapped potential, and build a brighter tomorrow. At Yaf
                Trading, the possibilities are limitless, and the opportunities
                are boundless. Let's venture into the future together, fueled by
                innovation, driven by excellence, and guided by a shared vision
                of success.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default AboutUs;
