import React from "react";
import Header from "../header/Header";
import Hero from "../hero/Hero";
import Feature from "../feature/Feature";
import Stats from "../stats/Stats";
import Step from "../steps/Step";
import Book from "../book/Book";
import Faq from "../faq/Faq";
import Footer from "../footer/Footer";
import LogoCloud from "../logoCloud/LogoCloud";

const Home = () => {
  return (
    <div>
      <Header />
      <Hero />
      <Feature />
      <Stats />
      <Step />
      <Book />
      <Faq />
      <LogoCloud />
      <Footer />
    </div>
  );
};

export default Home;
